import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styles from "./ContentLink.module.scss";
import {
  IArticleComponentData,
  IAttributes,
} from "../../../utils/interfaces/article";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { defaultAppLanguage } from "../../../redux/slice/article.slice";
interface ContentLinkDataProps {
  title: string;
  attributes: IAttributes;
}
interface ContentLinkProps {
  data?: {
    value: string | null;
    attributes: IAttributes;
  };
  onChange?: (data: IArticleComponentData) => void;
}

export interface ContentLinkRef {
  getData: () => IArticleComponentData;
}

const ContentLink: React.ForwardRefRenderFunction<
  ContentLinkRef,
  ContentLinkProps
> = ({ data, onChange }, ref) => {
  const [masterContent, setMasterContent] = useState<
    Record<string, ContentLinkDataProps>
  >({});
  const [linkData, setLinkData] = useState<ContentLinkDataProps | null>(null);

  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);

  useEffect(() => {
    if (data && data.value) {
      const parsedData = JSON.parse(data.value);
      setMasterContent(parsedData);
    }
  }, [data]);

  useEffect(() => {
    const currentAttributes = masterContent[defaultAppLanguage]?.attributes;
    const currentTitle = masterContent[defaultAppLanguage]?.title || "";
    const contentForLang = masterContent[language] || {
      title: currentTitle || "",
      attributes: currentAttributes || {},
    };
    setLinkData(contentForLang);
    prevLanguageRef.current = language;
  }, [language, masterContent]);

  useEffect(() => {
    if (onChange) {
      onChange(getData());
    }
  }, [linkData, onChange]);

  const getData = (): IArticleComponentData => {
    return {
      type: "LINK",
      value: JSON.stringify(masterContent),
      meta: {},
      attributes: linkData?.attributes || {},
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const handleChange = (event: any) => {
    const { value } = event.target;

    const updatedLinkData = {
      ...linkData,
      title: value || "",
      attributes: {
        ...linkData?.attributes,
      },
    };

    setLinkData(updatedLinkData);

    setMasterContent((prevData) => ({
      ...prevData,
      [language]: updatedLinkData,
    }));
  };

  const handleAttributesChange = (event: any) => {
    const { name, value } = event.target;

    const updatedAttributes = {
      ...linkData?.attributes,
      [name]: value !== undefined ? value : "",
    };

    const updatedLinkData = {
      ...linkData,
      attributes: updatedAttributes,
      title: linkData?.title || "",
    };

    setMasterContent((prevData) => {
      const updatedPrevData = {
        ...prevData,
        [language]: updatedLinkData,
      };

      const updatedMasterContent = Object.keys(updatedPrevData).reduce(
        (acc, lang) => {
          acc[lang] = {
            ...updatedPrevData[lang],
            title: updatedPrevData[lang].title || "",
            attributes: updatedAttributes,
          };
          return acc;
        },
        {} as Record<string, ContentLinkDataProps>
      );

      return updatedMasterContent;
    });

    setLinkData(updatedLinkData);
  };

  const targetTypes = [
    { value: "_blank", label: "New Tab" },
  ];

  const relTypes = [
    { value: "noreferrer", label: "No Referrer" },
    { value: "noopener", label: "No Opener" },
  ];

  return (
    <div className={styles["content-link-container"]}>
      <div></div>
      {linkData && (
        <>
          <div className={styles["input-field"]}>
            <TextField
              label="Title"
              name="title"
              value={linkData?.title || ""}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              label="href"
              name="href"
              value={linkData?.attributes.href || ""}
              onChange={handleAttributesChange}
            />
          </div>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="target-select-label">Target</InputLabel>
            <Select
              labelId="target-select-label"
              id="target-select"
              name="target"
              value={linkData?.attributes.target || ""}
              onChange={handleAttributesChange}
              label="Target"
            >
              {targetTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="rel-select-label">Rel</InputLabel>
            <Select
              labelId="rel-select-label"
              id="rel-select"
              name="rel"
              value={linkData?.attributes.rel || ""}
              onChange={handleAttributesChange}
              label="Rel"
            >
              {relTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default forwardRef(ContentLink);