import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { Autocomplete, TextField, Switch } from "@mui/material";
import styles from "./ImageSelector.module.scss";
import {
  IArticleComponentData,
  IAttributes,
} from "../../../utils/interfaces/article";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { defaultAppLanguage } from "../../../redux/slice/article.slice";
import { IAssetItem } from "../../../utils/interfaces/assets";
import { useAppSelector } from "../../../utils/hooks";
interface Image {
  title: string;
  url: string;
  attributes: IAttributes;
}
interface Data {
  type?: string;
  value: string | null;
  attributes?: IAttributes;
}

interface ImageSelectorProps {
  data: Data;
  isIcon?: boolean;
  onChange?: (data: IArticleComponentData) => void;
  disabled?: boolean;
}

export interface ImageSelectorRef {
  getData: () => IArticleComponentData;
}

const ImageSelector: React.ForwardRefRenderFunction<
  ImageSelectorRef,
  ImageSelectorProps
> = ({ data, onChange, isIcon, disabled }, ref) => {
  const [masterContent, setMasterContent] = useState<Record<string, Image>>({});
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [parsedDataValue, setParsedDataValue] = useState<Image | null>(null);

  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);

  const [images, setImages] = useState<Image[]>([]);
  const [autoCompleteValue, setAutocompleteValue] = useState<Image | null>(
    null
  );
  const { selectedApplication } = useAppSelector(
    (state: any) => state.application
  );
  const { assets: allAssets } = useAppSelector(
    (state: RootState) => state.asset
  );



  useEffect(() => {
    if (!allAssets.length) return;
    const allImages = allAssets.filter(
      (asset: IAssetItem): boolean => asset.type === "image"
    );
    const generateImageData = () => {
      let imgs: Image[] = [];
      allImages.forEach((img: IAssetItem) => {
        let item: Image = {
          title: img.title,
          url: img.url,
          attributes: {
            src: img.url,
            alt: img.alt,
            title: img.title,
            lazyLoading: true,
          },
        };
        imgs.push(item);
        if (parsedDataValue) {
          if (parsedDataValue.url && parsedDataValue.url === img.url)
            setAutocompleteValue(item);
        }
      });

      setImages(imgs);
    };

    generateImageData();
  }, [allAssets, parsedDataValue]);

  useEffect(() => {
    if (data && data.value) {
      const parsedData = JSON.parse(data.value);
      setMasterContent(parsedData);
            setParsedDataValue(parsedData);
    }
  }, [data]);

  useEffect(() => {
    const currentAttributes = masterContent[defaultAppLanguage]?.attributes;
    const contentForLang = masterContent[language] || {
      url: "",
      attributes: currentAttributes || {},
      name: "",
    };
        setSelectedImage(contentForLang);
    prevLanguageRef.current = language;
  }, [language, masterContent]);

  useEffect(() => {
    if (selectedImage && selectedImage.url && onChange) {
      onChange(getData());
    }
  }, [selectedImage]);

  const getData = (): IArticleComponentData => {
    return {
      type: "IMAGE",
      value:
        selectedImage && selectedImage.url
          ? JSON.stringify(selectedImage)
          : JSON.stringify(masterContent),
      meta: {},
      attributes:
        selectedImage && selectedImage.url
          ? selectedImage?.attributes
          : masterContent?.attributes || {},
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const onChangeImage = (event: React.ChangeEvent<{}>, value: Image | null) => {
    if (value) {
      // const currentAttributes = masterContent[defaultAppLanguage]?.attributes || {};
      const currentAttributes =
        value.attributes || masterContent[defaultAppLanguage]?.attributes || {};
      const updatedImage = {
        ...value,
        attributes: currentAttributes,
      };

      setMasterContent((prevData) => ({
        ...prevData,
        [language]: updatedImage,
      }));
      setSelectedImage(updatedImage);
      setAutocompleteValue(null);
    }
  };

  const handleSelectedImage = (key: string, value?: string | boolean) => {
    if (selectedImage) {
      const updatedAttributes = {
        ...selectedImage.attributes,
        [key]: value !== undefined ? value : "",
      };

      const updatedImage = { ...selectedImage, attributes: updatedAttributes };

      setMasterContent((prevData) => {
        const updatedPrevData = {
          ...prevData,
          [language]: updatedImage,
        };

        const updatedMasterContent = Object.keys(updatedPrevData).reduce(
          (acc, lang) => {
            acc[lang] = {
              ...updatedPrevData[lang],
              attributes: updatedAttributes,
            };
            return acc;
          },
          {} as Record<string, Image>
        );

        return updatedMasterContent;
      });
      setSelectedImage(updatedImage);
    }
  };

  return (
    <div className={styles["image-selector-container"]}>
      <div className={styles["selected-image"]}>
      {(selectedImage?.url || parsedDataValue?.attributes?.src) && (
        <img
          src={selectedImage?.url || parsedDataValue?.attributes?.src}
          alt={selectedImage?.attributes?.alt || parsedDataValue?.attributes?.alt || "Selected image"}
        />
      )}
      </div>
      <div className={styles["image-picker"]}>
        <div>
          <Autocomplete
            options={images}
            getOptionLabel={(option) => option.title}
            value={autoCompleteValue}
            onChange={onChangeImage}
            renderOption={(props, option) => (
              <li {...props}>
                <img
                  className={styles["thumbnail-image"]}
                  src={option.url}
                  alt={option.attributes.alt}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={isIcon ? "Choose icon" : "Choose image"}
              />
            )}
            disabled={disabled}
            noOptionsText="No image found"
          />
        </div>
        <div>
          <TextField
            label="Title"
            value={
              selectedImage?.title || parsedDataValue?.attributes?.title || ""
            }
            InputProps={{ readOnly: true }}
            disabled={disabled}
          />
        </div>
        <div>
          <TextField
            label="URL"
            value={selectedImage?.url || parsedDataValue?.url || ""}
            InputProps={{ readOnly: true }}
            disabled={disabled}
          />
        </div>
        {!isIcon && (
          <>
            <div>
              <TextField
                label="Alt-Text"
                value={
                  selectedImage?.attributes.alt ||
                  parsedDataValue?.attributes?.alt ||
                  ""
                }
                onChange={(e) => handleSelectedImage("alt", e.target.value)}
                disabled={disabled}
              />
            </div>
            <>
              <div>
                <label>Lazy Loading:</label>
                <Switch
                  checked={
                    selectedImage?.attributes.lazyLoading !== undefined
                      ? selectedImage?.attributes.lazyLoading
                      : (parsedDataValue?.attributes.lazyLoading || false)
                  }
                  onChange={(e) =>
                    handleSelectedImage("lazyLoading", e.target.checked)
                  }
                  inputProps={{ "aria-label": "toggle button" }}
                  aria-disabled={disabled}
                />
              </div>
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(ImageSelector);
