import { defaultAppLanguage } from "../../redux/slice/article.slice";

export const getLangData = (data: any, language: string = defaultAppLanguage) => {
    if (data === undefined || data === "") { return ""; }

    let dataO;
    if (typeof data === 'string') {
        try {
            dataO = JSON.parse(data);
        } catch (e) {
            // If data is not valid JSON, return the original data
            return data;
        }
    } else {
        dataO = data;
    }

    return (dataO[language]) ? dataO[language] : "";
};


export const updateLanguageValue = (targetObject: any, key: string, langCode: string, newValue: string): void => {
    const jsonString: string = targetObject[key];

    console.log(jsonString);
    try {
        let data;

        // Try parsing the JSON string into an object
        try {
            data = JSON.parse(jsonString);
        } catch (error) {
            // If parsing fails, assume jsonString is just a plain string, so we create a new object
            data = {};
        }

        // Ensure data is an object
        if (typeof data === 'object' && data !== null) {
            // Update the value for the specified language code
            data[langCode] = newValue;

            // Convert the updated object back to a JSON string
            targetObject[key] = JSON.stringify(data);
        } else {
            throw new Error('Invalid JSON format');
        }
    } catch (error) {
        console.error('Error updating language value:', error);
    }
};
