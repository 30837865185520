// App Configurable Settings
export const DEFAULT_DATE_FORMAT = "dd MMM yyyy";

// APP TEXT
export const APP_WELCOME = "Welcome, Strata CMS";
export const APP_TITLE = "Strata CMS";
export const FOOTER_TEXT = `Copyright © ${new Date().getFullYear()} Built with ♡ by Strata CMS`;
export const APP_LOGIN = "Login";
export const SUPPORT_TEAM_EMAIL = "strata-support@accenture.com";

// Article Component types
export enum ArticleComponentType {
  TEXT = "TEXT",
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  TASK = "TASK",
  LINK = "LINK",
}

// Table Component constants
export enum Order {
  Asc = "asc",
  Desc = "desc",
}

export enum ArticleStatus {
  Pending = "pending",
  Draft = "draft",
  Approved = "approved",
}

export enum SeverityType {
  Success = "success",
  Error = "error",
}

export const pageSizeOptions = [5, 10, 25];

export const gridInitialPagination = {
  pagination: {
    paginationModel: {
      pageSize: 5,
    },
  },
};

export const MEGABYTES_PER_BYTE = 1048576;
export const ASSET_SIZE_LIMIT = 50;
export const ASSET_UPLOAD_INDEX = 8;
export const AUTHORIZED_ROLES = ["Application Admin", "System Administrator"];
export const AUTHORIZED_PERMISSIONS = [
  "Application.Edit",
  "Application.UpdateRole",
];
export const APPLICATION_CREATE = "Application Create";

// LAZY Loading
export const DEFAULT_ITEMS_TO_LOAD = 10;
export const NEW_ITEMS_TO_LOAD = 10;

export const PAGE_NAMES = [
  {
    path: "users",
    name: "Users",
  },
  {
    path: "applications",
    name: "Applications",
  },
  {
    path: "assets",
    name: "Asset Library",
  },
  {
    path: "articles",
    name: "Article Library",
  },
];

export const OUTER_LOCATIONS = ["home", "logout"];

// Asset data renderer
export const UPLOAD_FAILED = "Upload failed";

export const ARTICLE_FILTER_OPTIONS = [
  {
    key: "status",
    value: "Status: Pending",
  },
  {
    key: "status",
    value: "Status: Approved",
  },
  {
    key: "status",
    value: "Status: Draft",
  },
  {
    key: "tag",
    value: "Tag...",
  },
  {
    key: "createdAt",
    value: "Created Date...",
  },
  {
    key: "updatedAt",
    value: "Updated Date...",
  },
  // For now this task will be on hold so commenting this code
  // {
  //   key: "language",
  //   value: "Language...",
  // },
];

export const FILTERS_WITH_INPUTS = ["tags", "min", "max", "format"];

export const ASSET_FILTER_OPTIONS = [
  {
    key: "resource_type",
    value: "Type: Image",
  },
  {
    key: "resource_type",
    value: "Type: Video",
  },
  // commenting this since we are not yet supporting
  // {
  //   key: "resource_type",
  //   value: "Type: Audio",
  // },
  // {
  //   key: "resource_type",
  //   value: "Type: pdf",
  // },
  {
    key: "tags",
    value: "Tag...",
  },
  {
    key: "format",
    value: "File Format...",
  },
  {
    key: "min",
    value: "Min-Size (MB)...",
  },
  {
    key: "max",
    value: "Max-Size (MB)...",
  },
  {
    key: "createdAt",
    value: "Created Date...",
  },
  {
    key: "updatedAt",
    value: "Updated Date...",
  },
];

export const ASSET_TYPE_VIDEO = 'video';
export const ASSET_TYPE_IMAGE = 'image';