import React, { FC, useEffect, useState } from "react";
import {
  AppBar,
  Typography,
  Toolbar,
  Select,
  IconButton,
  FormControl,
  ListItemIcon,
  ListItemText,
  List,
  ListItemButton,
  MenuItem,
  Collapse,
  InputLabel,
} from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  APP_TITLE,
  PAGE_NAMES,
  OUTER_LOCATIONS,
} from "../../utils/constants/constants";
import LeftMenuButtons from "./LeftMenuButtons";
import { actions as applicationActions } from "../../redux/slice/application.slice";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { BaseButton } from "../../utils/ui/buttons";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./Header.module.scss";
import { defaultAppLanguage } from "../../redux/slice/article.slice";
import { Language } from "../../utils/types";
import { RootState } from "../../redux/store";
interface Props {
  moduleName?: string;
  languages?: Language[];
}

const Header: FC<Props> = ({languages}): JSX.Element => {
  const { accounts } = useMsal();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | any>(false);
  const [open, setOpen] = React.useState<boolean[]>(Array().fill(false));
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { setSelectedMenu, setSelectedLanguage } = applicationActions;
  const location = useLocation();
  const LOCATION_PATHNAME = location.pathname.replace(/\//g, "");
  const dispatch = useAppDispatch();
  const { selectedApplication } = useAppSelector((state: RootState ) => state.application);
  const userPermissions = selectedApplication?.permissions;
  const isMenuOpen = useAppSelector((state: RootState) => state.application.isMenuOpen);
  const allRoles = useAppSelector((state: RootState ) => state.roles.roles);
  const [language, setLanguage] = useState(defaultAppLanguage);
  const { instance } = useMsal();
  const filteredButtons = LeftMenuButtons.filter((button) => {
    if (button.name === "Home") {     
        return true;
    }
    if (button.name === "Article Management") {
      if (
        userPermissions?.some((item: any) =>
          allRoles["ArticleCollections"]?.includes(item)
        )
      ) {
        return true;
      }
    }
    if (button.name === "User Management") {
      if (
        userPermissions?.some((item: any) =>
          allRoles["UserManagement"]?.includes(item)
        )
      ) {
        return true;
      }
    }
    if (button.name === "Application Management") {
      if (
        userPermissions?.some((item: any) => allRoles["Application"]?.includes(item))
      ) {
        return true;
      }
    }
    if (button.name === "Digital Asset Management") {
      if (
        userPermissions?.some((item: any) =>
          allRoles["MediaLibrary"]?.includes(item)
        )
      ) {
        return true;
      }
    }
    if (button.name === "API Edition Management") {
      //to be added later
    }
    return false;
  });

  let navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(accounts.length > 0);
  }, [accounts]);

  const goToHome = () => {
    navigate("/home");
    if (isMenuOpen === true) {
      dispatch(setSelectedMenu(!isMenuOpen));
    }
  };

  const handleItemClick = (index: any) => {
    const updatedOpen = [...open];
    updatedOpen[index] = !updatedOpen[index];
    setOpen(updatedOpen);
    setSelectedIndex(index);
    dispatch(setSelectedMenu(!isMenuOpen));
  };

  const handleLogout = () => {
    instance.logout();
    navigate("/pre-logout");
  };

  const handleMouseEnter = (index: number) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = true;
      return newOpen;
    });
  };

  const handleMouseLeave = (event: React.MouseEvent, index: number) => {
    const menuItem = document.getElementById(`menu-item-${index}`);
    const subMenu = document.getElementById(`sub-menu-${index}`);

    const isMouseInsideMenuItem = menuItem?.contains(
      event.relatedTarget as Node
    );
    const isMouseInsideSubMenu = subMenu?.contains(event.relatedTarget as Node);

    if (
      !event.relatedTarget ||
      (!isMouseInsideMenuItem && !isMouseInsideSubMenu)
    ) {
      setOpen((prevOpen) => {
        const newOpen = [...prevOpen];
        newOpen[index] = false;
        return newOpen;
      });
    }
  };

  const handleLanguageChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLanguage(e.target.value);
    // TODO enhancement to specify language options
    dispatch(setSelectedLanguage(e.target.value));
  };

  const getList = () => {
    return (
      <div className={styles.leftMenu}>
        <List className={styles.listContainer} component="nav">
          {filteredButtons.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemButton
                selected={selectedIndex === index}
                onClick={() => {
                  handleItemClick(index);
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={(event) => handleMouseLeave(event, index)}
                component={Link}
                to={item.link}
                id={`menu-item-${index}`}
                className={
                  selectedIndex === index
                    ? styles.selectedMenu
                    : styles.defaultMenu
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
                {item?.subMenu &&
                  (open[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItemButton>
              {item?.subMenu && (
                <Collapse in={open[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding id={`sub-menu-${index}`}>
                    {item.subMenu.map((subItem, subIndex) => {
                      const currentIndex = Number(`${index}${subIndex}`);
                      return (
                        <ListItemButton
                          key={subIndex}
                          selected={selectedIndex === currentIndex}
                          component={Link}
                          to={subItem.link}
                          onClick={() => handleItemClick(currentIndex)}
                          className={`${
                            selectedIndex === currentIndex
                              ? styles.selectedMenu
                              : styles.defaultMenu
                          } ${styles.pl4}`}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText primary={subItem.name} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  };

  const handleDisplayPageName = () => {
    const filteredPath = PAGE_NAMES.filter((item) => {
      return item.path.toLowerCase() === LOCATION_PATHNAME.toLowerCase();
    });
    return filteredPath.length > 0 ? `| ${filteredPath[0].name}` : " ";
  };

  return (
    <>
      <AppBar position="static" className={styles.headerContainer}>
        <Toolbar>
          {!isLoggedIn && !OUTER_LOCATIONS.includes(LOCATION_PATHNAME) ? (
            ""
          ) : OUTER_LOCATIONS.includes(LOCATION_PATHNAME) ||
            LOCATION_PATHNAME === "" ||
            !LOCATION_PATHNAME ? (
            ""
          ) : (
            <div>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="home"
                className={styles.mr2}
                onClick={() => dispatch(setSelectedMenu(!isMenuOpen))}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}

          <Typography variant="h6" component="div" className={styles.flexGrow1}>
            {APP_TITLE}
            <div className={styles.applicationName}>
              &nbsp;
              {isLoggedIn && selectedApplication.name
                ? `| ${selectedApplication.name}`
                : " "}
            </div>
            <div className={styles.applicationName}>
              &nbsp;{isLoggedIn && handleDisplayPageName()}
            </div>
            <div className={styles.floatRight}>
              <div className={styles.logoutContainer}>
                {isLoggedIn && (
                  <>
                    <FormControl
                      fullWidth
                      size="small"
                      className={styles.languageSelect}
                    >
                      <InputLabel>Language</InputLabel>
                      <Select
                        className={styles.headerSelect}
                        value={language}
                        label="Language"
                        onChange={handleLanguageChange}
                      >
                        { languages && languages.map(language =>
                          <MenuItem value={language.code} key={`key-${language.code}`}>{language.language} ({language.code.toUpperCase()})</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <BaseButton
                      text="Logout"
                      className={styles.headerButton}
                      variant="outlined"
                      handleClick={handleLogout}
                      size="medium"
                      color="primary"
                      icon={<LogoutIcon />}
                    />
                  </>
                )}
              </div>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      {isLoggedIn && isMenuOpen && <>{getList()}</>}
    </>
  );
};
export default Header;
