import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import { Autocomplete, TextField, Switch } from "@mui/material";
import styles from "./VideoSelector.module.scss";
import {
  IArticleComponentData,
  IAttributes,
} from "../../../utils/interfaces/article";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { defaultAppLanguage } from "../../../redux/slice/article.slice";
import { IAssetItem } from "../../../utils/interfaces/assets";
import { useAppSelector } from "../../../utils/hooks";

interface Video {
  name: string;
  url: string;
  attributes: IAttributes;
  thumbnail?: string;
}
interface Data {
  type?: string;
  value: string | null;
  attributes?: IAttributes;
}

interface VideoSelectorProps {
  data: Data;
  onChange?: (data: IArticleComponentData) => void;
}

export interface VideoSelectorRef {
  getData: () => IArticleComponentData;
}

const VideoSelector: React.ForwardRefRenderFunction<
  VideoSelectorRef,
  VideoSelectorProps
> = ({ data, onChange }, ref) => {
  const [masterContent, setMasterContent] = useState<Record<string, Video>>({});
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const language = useSelector(
    (state: RootState) => state.article.languageEditor
  );
  const prevLanguageRef = useRef<string>(language);
  const [videos, setVideos] = useState<Video[]>([]);
  const [autoCompleteValue, setAutocompleteValue] = useState<Video | null>(
    null
  );
  const { selectedApplication } = useAppSelector(
    (state: any) => state.application
  );
  const [parsedDataValue, setParsedDataValue] = useState<Video | null>(null);
  const { assets: allAssets } = useAppSelector(
    (state: RootState) => state.asset
  );

  useEffect(() => {
    if (!allAssets.length) return;
    const allVideos = allAssets.filter(
      (asset: IAssetItem): boolean => asset.type === "video"
    );
    const generateVideoData = () => {
      let videos: Video[] = [];
      allVideos.forEach((vid: IAssetItem) => {
        let item: Video = {
          name: vid.title,
          url: vid.url,
          thumbnail: vid.thumbnail,
          attributes: {
            src: vid.url,
            controls: false,
            autoplay: false,
            loop: false,
            muted: true,
            poster: "",
          },
        };
        videos.push(item);
        if (
          parsedDataValue &&
          parsedDataValue.url &&
          parsedDataValue.url === vid.url
        ) {
          setAutocompleteValue(item);
        }
      });
      setVideos(videos);
    };

    generateVideoData();
  }, [allAssets, parsedDataValue]);

  useEffect(() => {
    if (data && data.value) {
      const parsedData = JSON.parse(data.value);
      setMasterContent(parsedData);
      setParsedDataValue(parsedData);
    }
  }, [data]);

  useEffect(() => {
        const currentAttributes = masterContent[defaultAppLanguage]?.attributes;
    const contentForLang = masterContent[language] || {
      url: "",
      attributes: currentAttributes || {},
      name: "",
    };
    setSelectedVideo(contentForLang);
    prevLanguageRef.current = language;
  }, [language, masterContent]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.url && onChange) {
      onChange(getData());
    }
  }, [selectedVideo]);

  const getData = (): IArticleComponentData => {
    return {
      type: "VIDEO",
      value:
        selectedVideo && selectedVideo.url
          ? JSON.stringify(selectedVideo)
          : JSON.stringify(masterContent),
      meta: {},
      attributes:
        selectedVideo && selectedVideo.url
          ? selectedVideo?.attributes
          : masterContent?.attributes || {},
    };
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const handleVideoSelect = (
    event: React.ChangeEvent<{}>,
    value: Video | null
  ) => {
    if (value) {
      // const currentAttributes = masterContent[defaultAppLanguage]?.attributes || {};
      const currentAttributes =
        value.attributes || masterContent[defaultAppLanguage]?.attributes || {};
      const updatedVideo = {
        ...value,
        attributes: currentAttributes,
      };

      setMasterContent((prevData) => ({
        ...prevData,
        [language]: updatedVideo,
      }));

      setSelectedVideo(updatedVideo);
      setAutocompleteValue(null);
    }
  };

  const handleSelectedVideo = (key: string, value?: boolean | string) => {
    if (selectedVideo) {
      const updatedAttributes = {
        ...selectedVideo.attributes,
        [key]: value !== undefined ? value : "",
      };

      const updatedVideo = { ...selectedVideo, attributes: updatedAttributes };

      setMasterContent((prevData) => {
        const updatedPrevData = {
          ...prevData,
          [language]: updatedVideo,
        };

        const updatedMasterContent = Object.keys(updatedPrevData).reduce(
          (acc, lang) => {
            acc[lang] = {
              ...updatedPrevData[lang],
              attributes: updatedAttributes,
            };
            return acc;
          },
          {} as Record<string, Video>
        );

        return updatedMasterContent;
      });

      setSelectedVideo(updatedVideo);
    }
  };

  return (
    <div className={styles["video-selector-container"]}>
      <div className={styles["selected-video"]}>
        {selectedVideo && (
          <video controls src={selectedVideo?.url || parsedDataValue?.attributes?.src} width="320" height="240">
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className={styles["video-picker"]}>
        <div>
          <Autocomplete
            options={videos}
            getOptionLabel={(option) => option.name}
            value={autoCompleteValue}
            onChange={handleVideoSelect}
            renderOption={(props, option) => (
              <li {...props}>
                <img
                  className={styles["thumbnail-image"]}
                  src={option.thumbnail}
                  alt={option.attributes.alt}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Choose video" />
            )}
            noOptionsText="No video found"
          />
        </div>
        <div>
          <TextField
            label="File name"
            value={selectedVideo?.name || parsedDataValue?.name || ""}
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <TextField
            label="URL"
            value={selectedVideo?.url || parsedDataValue?.attributes.src || ""}
            InputProps={{ readOnly: true }}
          />
        </div>
        {selectedVideo && (
          <>
            <div>
              <label>Controls:</label>
              <Switch
                checked={
                  selectedVideo?.attributes.controls !== undefined
                    ? selectedVideo?.attributes.controls
                    : (parsedDataValue?.attributes.controls || false)
                }
                onChange={(e) =>
                  handleSelectedVideo("controls", e.target.checked)
                }
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Autoplay:</label>
              <Switch
                checked={
                  selectedVideo?.attributes.autoplay !== undefined
                    ? selectedVideo?.attributes.autoplay
                    : (parsedDataValue?.attributes.autoplay || false)
                }
                onChange={(e) =>
                  handleSelectedVideo("autoplay", e.target.checked)
                }
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Loop:</label>
              <Switch
                checked={
                  selectedVideo?.attributes.loop !== undefined
                    ? selectedVideo?.attributes.loop
                    : parsedDataValue?.attributes.loop || false
                }
                onChange={(e) => handleSelectedVideo("loop", e.target.checked)}
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Muted:</label>
              <Switch
                checked={
                  selectedVideo?.attributes.muted !== undefined
                    ? selectedVideo?.attributes.muted
                    : parsedDataValue?.attributes?.muted || false
                }
                onChange={(e) => handleSelectedVideo("muted", e.target.checked)}
                inputProps={{ "aria-label": "toggle button" }}
              />
            </div>
            <div>
              <label>Poster URL:</label>
              <TextField
                label="Poster URL:"
                value={
                  selectedVideo.attributes.poster ||
                  parsedDataValue?.attributes?.poster ||
                  ""
                }
                onChange={(e) => handleSelectedVideo("poster", e.target.value)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(VideoSelector);
