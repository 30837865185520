import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { IArticleComponentData } from '../../../utils/interfaces/article';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { defaultAppLanguage } from '../../../redux/slice/article.slice';
import { isValidJson } from '../../../utils/generic/string';
import xss from 'xss';
interface TextInputProps {
    data: {
        value: string | null;
    };
    onChange?: (data: IArticleComponentData) => void;
    errorChange?: (error: string | null, label: string) => void; 
    label: string;
    minLength: number;
    unilingual?: boolean;
    disabled?: boolean;
}

export interface TextInputRef {
    getData: () => IArticleComponentData;
}

const TextInput: React.ForwardRefRenderFunction<TextInputRef, TextInputProps> = ({
    data,
    label,
    minLength,
    onChange,
    errorChange,
    unilingual,
    disabled,
}, ref) => {
    const [masterContent, setMasterContent] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const language = useSelector((state: RootState) => state.article.languageEditor);
    const prevLanguageRef = useRef<string>(language);
    const dataRef = useRef<IArticleComponentData>({
        type: 'TEXT_INPUT',
        value: '',
        meta: {}
    });

    // Function to validate the input value
    const validateInput = (value: string) => {
        // Check if the field is required
        if (value.trim().length === 0) {
            return `${label} field is required.`;
        }
        // Check for minimum length
        if (value.trim().length < minLength) {
            return `${label} field must be at least ${minLength} characters long.`;
        }
        // If all checks pass, return null (no error)
        return null;
    };

    // Function to update input content
    const updateInputContent = (content?: string) => {
        const contentToUse = content || masterContent;
        const languageCode = getLanguage(language);
        const parsedContent = isValidJson(contentToUse)
            ? JSON.parse(contentToUse)
            : { [languageCode]: contentToUse };

        setInputValue(parsedContent[languageCode] || '');
        prevLanguageRef.current = languageCode;

        // Validate the content when it updates
        const validationError = validateInput(parsedContent[languageCode] || '');
        setError(validationError); // Set the error if there is one

        // Call errorChange if it exists
        if (errorChange) {
            errorChange(validationError, label);
        }
        
    };

    // Function to get the language
    const getLanguage = (language: string): string => {
        return unilingual ? defaultAppLanguage : language;
    };

    // Effect to handle data changes
    useEffect(() => {
        if (data && data.value) {
            setMasterContent(data.value);
            updateInputContent(data.value); // Update input and validate when data changes
        }
    }, [data]);

    // Effect to update input based on masterContent changes
    useEffect(() => {
        updateInputContent(masterContent);
    }, [masterContent]);

    // Effect to handle language changes
    useEffect(() => {
        if (prevLanguageRef.current !== language) {
            updateInputContent(masterContent);
        }
    }, [language]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = xss(e.target.value);
        if (inputValue === newValue) return;

        const validationError = validateInput(newValue);
        setError(validationError); // Set the error based on validation

        const languageCode = getLanguage(language);
        const currentMasterContent = 
        masterContent && isValidJson(masterContent) 
            ? JSON.parse(masterContent) 
            : masterContent ? { languageCode: masterContent } : {};
        const updatedContent = {
            ...currentMasterContent,
            [languageCode]: newValue,
        };

        setMasterContent(JSON.stringify(updatedContent));
        setInputValue(newValue);

        // Only call onChange if there's no validation error
        if (onChange && !validationError) {
            const latestData = getData();
            onChange(latestData);
        }
    };

    // Common Code to get the data
    const getData = (): IArticleComponentData => {
        return {
            ...dataRef.current,
            value: masterContent
        };
    };

    // Expose getData to parent component
    useImperativeHandle(ref, () => ({
        getData,
    }));

    return (
        <div>
            <TextField
                label={label}
                variant="outlined"
                value={inputValue}
                onChange={handleChange}
                fullWidth
                disabled={disabled}
                error={!!error} 
                helperText={error} 
            />
        </div>
    );
};

export default forwardRef(TextInput);
