import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import { TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./AssetFilterModal.module.scss";
import { DEFAULT_DATE_FORMAT } from "../../utils/constants/constants";
import { formatDate } from "../../utils/generic";
import { FILTERS_WITH_INPUTS } from "../../utils/constants/constants";

interface AssetFilterModalProps {
  openFilterModal: boolean;
  closeFilterModal: () => void;
  handleSaveFilters?: () => void;
  filterTitle?: string;
  filterErrorMessage?: string;
  filterType: string;
  filterValue?: any;
  handleSetFilterValues: (e: any) => void;
}

export const AssetFilterModal = ({
  openFilterModal,
  closeFilterModal,
  handleSaveFilters,
  filterTitle,
  filterErrorMessage,
  filterType,
  filterValue,
  handleSetFilterValues,
}: AssetFilterModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | any>();

  useEffect(() => {
    setSelectedDate(null);
  }, [openFilterModal]);

  const handleCloseModal = () => {
    setSelectedDate(null);
    closeFilterModal();
  };

  return (
    <CustomModal
      open={openFilterModal}
      onClose={handleCloseModal}
      onSave={handleSaveFilters}
      title={`Please enter ${filterTitle}`}
      content=""
    >
      <div className={styles["filterModal"]}>
        <div className={styles["filterError"]}>{filterErrorMessage}</div>
        {filterType === "min" || filterType === "max" ? (
          <TextField
            placeholder="Type a number"
            value={filterValue}
            type="number"
            onChange={(e) => handleSetFilterValues(e.target.value)}
          />
        ) : (
          FILTERS_WITH_INPUTS.includes(filterType) && (
            <TextField
              id="outlined-basic"
              value={filterValue}
              onChange={(e) => handleSetFilterValues(e.target.value)}
            />
          )
        )}

        {(filterType === "createdAt" || filterType === "updatedAt") && (
          <>
            <DatePicker
              className={styles["filterByDate"]}
              selected={selectedDate && selectedDate}
              onChange={(e: any) => {
                setSelectedDate(formatDate(e, DEFAULT_DATE_FORMAT));
                handleSetFilterValues(formatDate(e, DEFAULT_DATE_FORMAT));
              }}
              dateFormat={DEFAULT_DATE_FORMAT}
              placeholderText="Select a date"
            />
          </>
        )}
      </div>
    </CustomModal>
  );
};
